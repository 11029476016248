import React from 'react';
import styles from "../../components/landingPage.module.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link , navigate} from "gatsby"
import SiteVersion from '../../components/changeSites';
import Footer from "../../components/landingFooter"
import MainLandingDisclaimerPopup from "../../components/prompts/disclaimers/main-landing/disclaimer-popup"
import { getUkResidentsHCPLandingDisclaimerPopupConfig, getUkResidentsNonHCPLandingDisclaimerPopupConfig, getDefaultMainLandingDisclaimerPopupConfigsUKResidents} from "../../util/configs"
import UkResidentsLandingDisclaimer from "./../../components/prompts/disclaimers/ukResidents/user-selection-disclaimer"
import SEO from "../../components/seo"
import {config} from "../../util/configs"
import {ValidateRoute, ShowDisclaimer} from "../../util/route-helper"

class LandingPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            popupConfig: null,
            shouldRender: false,
            directLinkPopUpConfig: null
        }
    }

    componentDidMount(){
        let shouldRender = ValidateRoute();

        this.setState({shouldRender});

        ShowDisclaimer("UkResidents-Landing",getDefaultMainLandingDisclaimerPopupConfigsUKResidents(null, true), this);
        localStorage.setItem("UkResidents-Landing", JSON.stringify({ disclaimerShown: true }));

    }
   
    toggleUserSelectionDisclaimerPopup = (path, userType) => {
        let popupConfig = {}
        switch(userType){
            case "HCP":
                popupConfig = getUkResidentsHCPLandingDisclaimerPopupConfig(path);
                localStorage.setItem("ukresidents-hcp", JSON.stringify({ disclaimerShown: true }));
                break;
            case "NonHCP":
                popupConfig = getUkResidentsNonHCPLandingDisclaimerPopupConfig(path);
                localStorage.setItem("ukresidents-nonhcp", JSON.stringify({ disclaimerShown: true }));
                break;
        }
        this.setState({popupConfig: popupConfig})
    }

    render() {
        const { shouldRender, directLinkPopUpConfig} = this.state;

        if(!shouldRender){
            return <React.Fragment />
        }

        return ( 
            <div>
            <SEO title="UK Residents" />
            <div className={styles.landing_wrap}>
            <div className={styles.landing_logo}>
              <div className="container">
             <div className="row">
                 <div className="col-4 col-sm-4 col-md-6">
            <Link to={`${config.defaultBaseUrl}/`}>
              <span className={styles.logo}></span>
              </Link>
                
                 </div>
                 <div className="col-8 col-sm-8 col-md-6 p-4 text-center">
                 
            
            
              <ul>
                 
                  <li>
            <Link to={`${config.defaultBaseUrl}/`}>
                  Global Site
              </Link> 
                  </li>
                  <li>
            <Link to={`${config.defaultBaseUrl}/`}>
                  Investors
              </Link>
                  </li>
              </ul>
                
                 </div>
             </div>
                      
                    </div>
              </div>
                <div className="container">
                <SiteVersion/>
                        {directLinkPopUpConfig && directLinkPopUpConfig.show && <MainLandingDisclaimerPopup popupConfig={directLinkPopUpConfig} hide={() => this.setState({ directLinkPopUpConfig: null })} />}
                    {this.state.popupConfig && this.state.popupConfig.show && <UkResidentsLandingDisclaimer popupConfig={this.state.popupConfig} hide={() => this.setState({popupConfig: null})} />}
                    <div className="row">
                        <div className={`col-12 col-sm-12 col-md-12 ${styles.welcome_section}`}>
                        
                        <ScrollAnimation animateIn="fadeInUp" delay={100}>
                            <h2>Dedicated to <span>developing</span> hormone therapeutics</h2>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" delay={700}>
                            <p>A global pharmaceutical company focused on chronic endocrine conditions</p>
                       </ScrollAnimation>
                        </div>
                      </div>
                    <div className="row justify-content-center">
                        <div className={`col-12 col-sm-12 col-md-12 col-lg-4  ${styles.our_services}`}>
                        <ScrollAnimation animateIn="fadeIn">
                            
                        <div onClick={() => this.toggleUserSelectionDisclaimerPopup("/UkResidents/HCP/home", "HCP")}>
                            <div className={`row ${styles.landing_page_sections}`}>
                                <div className="col-12 col-sm-12 col-md-12  pl-0">
                                    <div className={styles.card_border}>
                                    <div className={styles.card}>
                                        <div className={styles.content}>
                                            <h2 className={styles.title}>UK Healthcare<br/> Professionals</h2>
                                        </div>
                                     </div>
                                    </div>
                                </div>
                                
                            </div>
                            </div>
                            </ScrollAnimation>
                        </div>
                        <div className={`col-12 col-sm-12 col-md-12 col-lg-4   ${styles.our_services}`}>
                        <ScrollAnimation animateIn="fadeIn">
                        <div onClick={() => navigate("/UkResidents/Non-HCP/home")}>
                        <div className={`row  ${styles.landing_page_sections} ${styles.non_health}`}>
                                <div className="col-12 col-sm-12 col-md-12 pl-0">
                                <div className={styles.card_border}>
                                        <div className={styles.card}>
                                        <div className={styles.content}>
                                        <h2 className={styles.title}>UK <br/>Patients/Public</h2>
                                       </div>
                                 </div>
                                </div>
                                   
                                 </div>
                                
                            </div>
                    </div>
                    </ScrollAnimation>
                        </div>
                     
                    </div>
                </div>
            </div>
             <Footer/>
             </div>
         );
    }
}
 
export default LandingPage